"use client";

import { HeartIcon, MagnifyingGlassIcon, ShoppingCartIcon, UserIcon } from "@heroicons/react/24/outline";
import { PopoverGroup, Popover, PopoverPanel, PopoverButton } from "@headlessui/react";
import { cookies } from "next/headers";
import { getCart } from "@/app/api/shop/cart/route";
import Link from "next/link";
import { redirect } from "next/navigation";
import SearchBar from "./SearchBar";
import { useCart } from "./CartContext";
export const navigation = {
  categories: [{
    name: "Categories",
    featured: [{
      name: "Sleep",
      href: "#"
    }, {
      name: "Swimwear",
      href: "#"
    }, {
      name: "Underwear",
      href: "#"
    }],
    collection: [{
      name: "Everything",
      href: "#"
    }, {
      name: "Core",
      href: "#"
    }, {
      name: "New Arrivals",
      href: "#"
    }, {
      name: "Sale",
      href: "#"
    }],
    categories: [{
      name: "Basic Tees",
      href: "#"
    }, {
      name: "Artwork Tees",
      href: "#"
    }, {
      name: "Bottoms",
      href: "#"
    }, {
      name: "Underwear",
      href: "#"
    }, {
      name: "Accessories",
      href: "#"
    }],
    brands: [{
      name: "Full Nelson",
      href: "#"
    }, {
      name: "My Way",
      href: "#"
    }, {
      name: "Re-Arranged",
      href: "#"
    }, {
      name: "Counterfeit",
      href: "#"
    }, {
      name: "Significant Other",
      href: "#"
    }]
  }],
  pages: [
    // { name: "Catalog", href: "/catalog" },
    // { name: "Stores", href: "#" },
  ]
};
const Header = () => {
  // const cookieStore = cookies();
  // const session = cookieStore.get("session");

  // const cartItems = await getCart(session?.value);
  const {
    cartItemsCount
  } = useCart();
  return <div data-sentry-component="Header" data-sentry-source-file="Header.tsx">
      {/* Mobile menu */}

      {/* End Mobile menu */}

      {/* Desktop menu  */}

      <header className="fixed top-0 z-10 w-full">
        <nav aria-label="Top">
          {/* Top navigation */}
          {/* <div className="bg-gray-900">
            <div className="mx-auto flex h-10 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
               <form className="hidden lg:block lg:flex-1">
                <div className="flex">
                  <label htmlFor="desktop-currency" className="sr-only">
                    Currency
                  </label>
                </div>
              </form>
               <p className="flex-1 text-center text-sm font-medium text-white lg:flex-none">
                Get free delivery on orders over $100
              </p>
               <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                 <a
                  href="#"
                  className="text-sm font-medium text-white hover:text-gray-100"
                >
                  Create an account
                </a>
                <span aria-hidden="true" className="h-6 w-px bg-gray-600" />
                <a
                  href="#"
                  className="text-sm font-medium text-white hover:text-gray-100"
                >
                  Sign in
                </a> 
              </div>
            </div>
           </div> */}

          {/* Secondary navigation */}
          <div className="bg-white">
            <div className="border-b border-gray-200">
              <div className="mx-auto  px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  {/* Logo (lg+) */}
                  <div className="hidden lg:flex lg:items-center">
                    <a href="/" className="flex flex-row justify-center align-middle">
                      <img alt="" src="/evoke-logos/SVG/Symbol/Symbol_C_N.svg" className="h-10 w-auto" />
                      <span className="sr-only">Evoke</span>
                      <img alt="" src="/evoke.svg" className="h-10 w-auto" />
                    </a>
                  </div>

                  <div className="hidden h-full lg:flex">
                    {/* Mega menus */}
                    <PopoverGroup className="ml-8" data-sentry-element="PopoverGroup" data-sentry-source-file="Header.tsx">
                      <div className="flex h-full justify-center space-x-8">
                        {navigation.categories.map((category, categoryIdx) => <Popover key={category.name} className="flex">
                            <div className="relative flex">
                              <PopoverButton className="relative z-10 -mb-px flex hidden items-center border-b-2 border-transparent pt-px text-sm font-medium text-gray-700 transition-colors duration-200 ease-out hover:text-gray-800 focus-visible:outline-none data-[open]:border-orange-600 data-[open]:text-orange-600">
                                {category.name}
                              </PopoverButton>
                            </div>

                            <PopoverPanel transition className="absolute inset-x-0 top-full text-gray-500 transition data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in sm:text-sm">
                              {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                              <div aria-hidden="true" className="absolute inset-0 top-1/2 bg-white shadow" />

                              <div className="relative bg-white">
                                <div className="mx-auto max-w-7xl px-8">
                                  <div className="grid grid-cols-2 items-start gap-x-8 gap-y-10 pb-12 pt-10">
                                    <div className="grid grid-cols-2 gap-x-8 gap-y-10">
                                      <div>
                                        <p id={`desktop-featured-heading-${categoryIdx}`} className="font-medium text-gray-900">
                                          Featured
                                        </p>
                                        <ul role="list" aria-labelledby={`desktop-featured-heading-${categoryIdx}`} className="mt-6 space-y-6 sm:mt-4 sm:space-y-4">
                                          {category.featured.map(item => <li key={item.name} className="flex">
                                              <a href={item.href} className="hover:text-gray-800">
                                                {item.name}
                                              </a>
                                            </li>)}
                                        </ul>
                                      </div>
                                      <div>
                                        <p id="desktop-categories-heading" className="font-medium text-gray-900">
                                          Categories
                                        </p>
                                        <ul role="list" aria-labelledby="desktop-categories-heading" className="mt-6 space-y-6 sm:mt-4 sm:space-y-4">
                                          {category.categories.map(item => <li key={item.name} className="flex">
                                              <a href={item.href} className="hover:text-gray-800">
                                                {item.name}
                                              </a>
                                            </li>)}
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="grid grid-cols-2 gap-x-8 gap-y-10">
                                      <div>
                                        <p id="desktop-collection-heading" className="font-medium text-gray-900">
                                          Collection
                                        </p>
                                        <ul role="list" aria-labelledby="desktop-collection-heading" className="mt-6 space-y-6 sm:mt-4 sm:space-y-4">
                                          {category.collection.map(item => <li key={item.name} className="flex">
                                              <a href={item.href} className="hover:text-gray-800">
                                                {item.name}
                                              </a>
                                            </li>)}
                                        </ul>
                                      </div>

                                      <div>
                                        <p id="desktop-brand-heading" className="font-medium text-gray-900">
                                          Brands
                                        </p>
                                        <ul role="list" aria-labelledby="desktop-brand-heading" className="mt-6 space-y-6 sm:mt-4 sm:space-y-4">
                                          {category.brands.map(item => <li key={item.name} className="flex">
                                              <a href={item.href} className="hover:text-gray-800">
                                                {item.name}
                                              </a>
                                            </li>)}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </PopoverPanel>
                          </Popover>)}

                        {navigation.pages.map(page => <a key={page.name} href={page.href} className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800">
                            {page.name}
                          </a>)}
                      </div>
                    </PopoverGroup>
                  </div>

                  {/* Mobile menu and search (lg-) */}
                  {/* <div className="flex flex-1 items-center lg:hidden">
                    <button
                      type="button"
                      onClick={() => setMobileMenuOpen(true)}
                      className="-ml-2 rounded-md bg-white p-2 text-gray-400"
                    >
                      <span className="sr-only">Open menu</span>
                      <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                    </button>
                   */}

                  {/* Logo (lg-) */}
                  <a href="/" className="lg:hidden">
                    <span className="sr-only">Evoke</span>
                    <img alt="" src="/evoke-logos/SVG/Symbol/Symbol_C_N.svg" className="h-10 w-auto" />
                  </a>
                  <SearchBar data-sentry-element="SearchBar" data-sentry-source-file="Header.tsx" />

                  <div className="flex flex-1 items-center justify-end">
                    <div className="flex items-center lg:ml-8">
                      <div className="flex space-x-8">
                        {/* TODO: add back in search in header */}
                        <div className="hidden bg-gray-200 sm:flex">
                          {/* <a
                            href="/catalog"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                           >
                            <span className="sr-only">Search</span>
                            <MagnifyingGlassIcon
                              aria-hidden="true"
                              className="h-6 w-6"
                            />
                           </a> */}
                        </div>

                        <div className="flex">
                          <Link href="/likes" className="-m-2 p-2 text-gray-400 hover:text-gray-500" data-sentry-element="Link" data-sentry-source-file="Header.tsx">
                            <span className="sr-only">Account</span>
                            <HeartIcon aria-hidden="true" className={`h-6 w-6 ${false // pathname.includes("likes") ? "text-red-500" : ""
                          }`} data-sentry-element="HeartIcon" data-sentry-source-file="Header.tsx" />
                          </Link>
                        </div>
                      </div>

                      <span aria-hidden="true" className="mx-4 h-6 w-px bg-gray-200 lg:mx-6" />

                      <div className="flow-root">
                        <a href="/cart" className="group -m-2 flex items-center p-2">
                          <ShoppingCartIcon aria-hidden="true" className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500" data-sentry-element="ShoppingCartIcon" data-sentry-source-file="Header.tsx" />
                          <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                            {cartItemsCount ?? ""}
                          </span>
                          <span className="sr-only">
                            items in cart, view bag
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>;
};
export default Header;