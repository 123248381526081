"use client";

import { printType, product } from "@prisma/client";
import { usePathname, useRouter } from "next/navigation";
import React, { createContext, useState, useContext, useEffect } from "react";
export interface ShippingMethod {
  price: number;
  method: string;
}
export interface CartItem {
  cartId: number;
  createdAt: string;
  id: number;
  printType: printType;
  printTypeId: number;
  product: product;
  productId: number;
  quantity: number;
  updatedAt: string;
}
export interface Pricing {
  itemsPrice: number;
  shipping: ShippingMethod[];
}
export interface Cart {
  cartItems: CartItem[];
  pricing: Pricing;
}
export interface ShippingOptions {
  cartItems: any[];
  pricing: {
    shipping: {
      price: number;
      method: "budget" | "standard" | "express" | "overnight";
    }[];
  };
}
interface CartContextType {
  cartItemsCount: number;
  updateCartItemsCount: (count: number) => void;
  cart: Cart | undefined;
  setCart: (cart: Cart) => void;
}
const CartContext = createContext<CartContextType | undefined>(undefined);
export const CartProvider: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const [cart, setCart] = useState<Cart | undefined>(undefined);
  const pathname = usePathname();
  const fetchCart = () => {
    fetch("/api/shop/cart").then(response => response.json()).then(data => {
      const count = data.cartItems.reduce((total, item) => total + item.quantity, 0);
      setCartItemsCount(count);
      setCart(data);
    }).catch(error => console.error("Error fetching cart:", error));
  };
  useEffect(() => {
    // Fetch initial cart count
    fetchCart();
  }, []);
  useEffect(() => {
    // Refetch cart when route changes to "/cart"
    if (pathname === "/cart") {
      fetchCart();
    }
  }, [pathname]);
  const updateCartItemsCount = (count: number) => {
    setCartItemsCount(count);
  };
  return <CartContext.Provider value={{
    cartItemsCount,
    updateCartItemsCount,
    cart,
    setCart
  }} data-sentry-element="unknown" data-sentry-component="CartProvider" data-sentry-source-file="CartContext.tsx">
      {children}
    </CartContext.Provider>;
};
export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};