"use client";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/navigation";
import React, { useState, useEffect, useRef } from "react";
export default function Search({
  hero = false
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const router = useRouter();
  const searchRef = useRef(null);
  const debounceTimerRef = useRef(null);
  const fetchSuggestions = async query => {
    try {
      const response = await fetch(`/api/shop/search/autocomplete?q=${query}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (response.ok) {
        const data: string[] = await response.json();
        setSuggestions(data);
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };
  useEffect(() => {
    if (searchQuery.length > 0 && !isSubmitted) {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
      debounceTimerRef.current = setTimeout(() => {
        fetchSuggestions(searchQuery);
      }, 1000);
    } else {
      setSuggestions([]);
    }
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, [searchQuery, isSubmitted]);
  useEffect(() => {
    function handleClickOutside(event) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSuggestions([]);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleSuggestionClick = suggestion => {
    setSuggestions([]);
    setSearchQuery(suggestion);
    setIsSubmitted(true);
    router.push(`/?q=${suggestion}`, {
      scroll: true
    });
  };
  const handleSubmit = e => {
    e.preventDefault();
    setSuggestions([]);
    setIsSubmitted(true);
    if (!searchQuery) {
      router.push(`/`);
    } else {
      router.push(`/?q=${searchQuery}`, {
        scroll: true
      });
    }
  };
  const handleInputChange = e => {
    setSearchQuery(e.target.value);
    setIsSubmitted(false);
  };
  return <div ref={searchRef} className={`justify-centerbg-gray-300 relative ml-3 flex max-w-2xl flex-1 items-center ${hero ? "" : ""}`} data-sentry-component="Search" data-sentry-source-file="SearchBar.tsx">
      <form onSubmit={handleSubmit} className="w-full">
        <div className={`pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 ${hero ? "" : ""}`}>
          <MagnifyingGlassIcon className="h-6 w-6 text-gray-400" data-sentry-element="MagnifyingGlassIcon" data-sentry-source-file="SearchBar.tsx" />
        </div>
        <input value={searchQuery} onChange={handleInputChange} className="w-full rounded-full border-none bg-gray-100 pl-10 focus:ring-orange-600" placeholder="Search..." style={{
        height: hero ? "64px" : ""
      }} />
      </form>
      {suggestions.length > 0 && !isSubmitted && <ul className={`absolute left-0 right-0 top-full z-20 mt-1 max-h-40 w-full overflow-y-auto rounded-3xl border border-gray-300 bg-white`}>
          {suggestions.map((suggestion, index) => <li key={index} className={`cursor-pointer px-4 py-2 text-left hover:bg-gray-100`} onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion}
            </li>)}
        </ul>}
    </div>;
}