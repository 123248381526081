import(/* webpackMode: "eager" */ "/opt/render/project/src/app/css/style.css");
;
import(/* webpackMode: "eager", webpackExports: ["CartProvider"] */ "/opt/render/project/src/components/CartContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/opt/render/project/src/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter_Tight\",\"arguments\":[{\"weight\":[\"500\",\"600\",\"700\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"variable\":\"--font-inter-tight\",\"display\":\"swap\"}],\"variableName\":\"inter_tight\"}");
